"use client";

import * as React from "react";
import { Moon, Sun } from "lucide-react";
import { useTheme } from "next-themes";

import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useTranslations } from "next-intl";
import { getFQCN } from "@/lib/helpers/fqcn/methods";
import { IFQCN_BUI } from "@/utils/types/common";
import { FC } from "react";

type Props = {
  fqcn_bui: IFQCN_BUI;
};

const ModeToggle: FC<Props> = ({ fqcn_bui }) => {
  const { setTheme } = useTheme();
  const t = useTranslations("ModeToggle");

  return (
    <div className="print:hidden" id={getFQCN(fqcn_bui, "mode-toogle")}>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="outline" className="border-gray-300" size="icon">
            <Sun className="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
            <Moon className="absolute h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
            <span className="sr-only">Toggle theme</span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem
            id={getFQCN(fqcn_bui, "light-toogle")}
            onClick={() => setTheme("light")}
          >
            {t("Light")}
          </DropdownMenuItem>
          <DropdownMenuItem
            id={getFQCN(fqcn_bui, "dark-toogle")}
            onClick={() => setTheme("dark")}
          >
            {t("Dark")}
          </DropdownMenuItem>
          <DropdownMenuItem
            id={getFQCN(fqcn_bui, "system-toogle")}
            onClick={() => setTheme("system")}
          >
            {t("System")}
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

export default ModeToggle;
