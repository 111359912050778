import React from "react";

const PageLoader: React.FC = () => {
  return (
    <div
      data-testid="loading-screen"
      style={{ zIndex: 1 }}
      className="flex flex-col space-x-2 items-center w-full h-full justify-center"
    >
      <div className="flex flex-col items-center justify-center space-y-4 absolute top-1/4">
        <div className="flex items-center justify-center space-x-3">
          <div className="w-6 h-6 rounded-lg bg-blue-phpr animate-bounce"></div>
          <div
            className="w-6 h-6 rounded-lg bg-blue-phpr animate-bounce"
            style={{ animationDelay: "200ms" }}
          ></div>
          <div
            className="w-6 h-6  rounded-lg bg-blue-phpr  animate-bounce"
            style={{ animationDelay: "400ms" }}
          ></div>
        </div>
        <p className="text-gray-600 font-bold text-xl">Loading...</p>
      </div>
    </div>
  );
};

export default PageLoader;
