"use client";

import { GetTenantCore } from "./GetTenantBackend";

//Checks the url for tenant
//If url not long enough or takes localhost, uses environnement variable instead
export default function GetTenant() {
  var urlSplit: Array<string> | undefined;
  if (typeof window !== "undefined")
    urlSplit = window.location.hostname.split(".");

  return GetTenantCore(urlSplit);
}
