import { useEffect, useState } from "react"

export default function Clock(){
    const [date, setDate] = useState<Date>(new Date());
    const [intervalID, setIntervalID] = useState<NodeJS.Timeout|undefined>(undefined);
    const [isLoading, setIsLoading] = useState(true);

    function displayTime(){
        return date.getHours().toString().padStart(2,"0") + ":" + date.getMinutes().toString().padStart(2,"0");
    }

    function displayDate(){
        return date.getFullYear() + "/" + date.getMonth().toString().padStart(2,"0") + "/" + date.getDate().toString().padStart(2,"0");
    }

    useEffect(() =>{
        if(typeof(window) !== "undefined"){
            if(intervalID == undefined){
                setIntervalID(setInterval(() => {
                    setDate(new Date());
                }, 1000))
            }else{
                clearInterval(intervalID);
            }
        }
    }, [isLoading]);

    return(
        <div className="flex grid grid-rows-2 text-center" onLoad={() => setIsLoading(false)}>
          <p>{displayTime()}</p>
          <p>{displayDate()}</p>
        </div>
    );
}