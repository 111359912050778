// utils/errorHandler.js
import * as Sentry from "@sentry/nextjs";
import { toast } from "sonner";

export const errorHandler = (
  error: Error,
  message = "An error occurred : ",
) => {
  console.error(message, error);

  Sentry.captureException(message + error);

  toast.error(message);
};
